<script setup>
import { boolean, mixed, object, string } from 'yup';
import { reactive, useNuxtApp } from '#imports';
import { Form as VeeForm } from 'vee-validate';
import useFile from '@/composables/useFile';
import { useMessagePopup } from '@/composables/useMessagePopup';
import { useDocumentLinks } from '@/composables/useDocumentLinks';
import { useFormHelp } from '@/composables/useFormHelp';
import { useFeedbackStore } from '@/stores/feedback';

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';
import UiInput from '@/components/ui/form/UiInput.vue';
import UiFileMultiple from '@/components/ui/form/UiFileMultiple.vue';
import UiSelect from '@/components/ui/form/UiSelect.vue';
import UiCheckbox from '@/components/ui/form/UiCheckbox.vue';
import UiTextarea from '@/components/ui/form/UiTextarea.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import FormWrapper from '@/components/layout/forms/FormWrapper.vue';

const fileComposable = useFile();
const { $tp } = useNuxtApp();
const { openMessagePopup } = useMessagePopup();
const { privacyPolicy, termsConditions } = useDocumentLinks();
const formHelpComposable = useFormHelp();
const feedbackStore = useFeedbackStore();

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
});

//<editor-fold desc="Form">
const formData = reactive({
  username: '',
  email: '',
  topic: null,
  comment: '',
  files: null,
  policy: false,
});

const themeList = [
  { id: 0, name: 'О продукте' },
  { id: 1, name: 'Регистрация' },
  { id: 2, name: 'Восстановление пароля' },
  { id: 3, name: 'Отписка от рассылок' },
  { id: 4, name: 'Заблокированная учетная запись' },
  { id: 5, name: 'Предложения и пожелания' },
  { id: 6, name: 'Другие вопросы по сайту' },
];
function sendForm(values, actions) {
  const data = {
    username: values.username,
    email: values.email,
    topic: values.topic.name,
    comment: values.comment,
    files: values.files,
    policy: values.policy,
  };
  feedbackStore
    .sendFeedback(data)
    .then(() => {
      openMessagePopup('Ваше обращение отправлено');
      props.closeModal?.();
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const maxMessageLength = 501;
const accept = `${fileComposable.fileTypes.png},${fileComposable.fileTypes.jpg},${fileComposable.fileTypes.jpeg},${fileComposable.fileTypes.gif},${fileComposable.fileTypes.bmp},`;

const validationSchema = object({
  username: string().required().trim(),
  email: string().required().email().trim(),
  topic: mixed().required(),
  comment: string()
    .required()
    .max(maxMessageLength - 1),
  files: mixed()
    .notRequired()
    .minFileListSizeKb(50)
    .maxFileListSize(fileComposable.getBitesFromMb(10))
    .maxFileLength(3)
    .fileListTypes([
      fileComposable.fileTypes.png,
      fileComposable.fileTypes.jpg,
      fileComposable.fileTypes.jpeg,
      fileComposable.fileTypes.gif,
      fileComposable.fileTypes.bmp,
    ]),
  policy: boolean().required().isTrue(),
});
// </editor-fold>
</script>

<template>
  <PopupCenter>
    <template #default>
      <FormWrapper
        class="feedback-popup"
        heading="Обращение в службу поддержки"
        description="Напишите нам о проблеме и специалисты свяжутся с вами. Ответ поступит на указанный адрес электронной почты."
      >
        <template #content>
          <VeeForm
            v-slot="{ isSubmitting }"
            :validation-schema="validationSchema"
            class="feedback-popup__form"
            @submit="sendForm"
          >
            <UiInput
              v-model="formData.username"
              name="username"
              label="Имя"
              hint="Введите имя"
              class="feedback-popup__field"
            />

            <UiInput
              v-model="formData.email"
              name="email"
              label="e-mail"
              hint="Введите электронную почту"
              class="feedback-popup__field"
            />

            <UiSelect
              v-model="formData.topic"
              :options="themeList"
              label="тема обращения"
              hint="Выберите из списка"
              show-errors
              name="topic"
              track-displayed-by="name"
              track-by="id"
              is-clear-button
              class="feedback-popup__field"
            />

            <UiTextarea
              v-model="formData.comment"
              label="сообщение"
              name="comment"
              :max-length="maxMessageLength"
              class="feedback-popup__field"
            />

            <UiFileMultiple
              v-model="formData.files"
              :accept="accept"
              name="files"
              class="feedback-popup__item"
              label="Перетащите файл в эту область или выберите его на устройстве"
              label-mobile="Выберите файл для загрузки<br> на своем устройстве"
              hint="Вы можете прикрепить до трех файлов. Допустимый формат файла JPG, PNG, GIF, BMP (размер не менее 50 кБ и не более 10 мБ)."
            />

            <div class="feedback-popup__item feedback-popup__item--checkbox">
              <UiCheckbox
                v-model="formData.policy"
                name="policy"
                class="feedback-popup__field"
                theme="is-overlay"
              >
                <span
                  class="feedback-popup__font feedback-popup__font--checkbox"
                >
                  Я даю
                  <a target="_blank" :href="termsConditions">согласие</a>
                  {{
                    $tp(
                      ' на обработку персональных данных и подтверждаю ознакомление с',
                    )
                  }}
                  <a target="_blank" :href="privacyPolicy"
                    >Политикой конфиденциальности</a
                  >
                </span>
              </UiCheckbox>
            </div>

            <div>
              <UiButton
                type="submit"
                class="feedback-popup__button feedback-popup__button--submit"
                text="Отправить"
                :is-disabled="isSubmitting"
                :disabled="isSubmitting"
              />
            </div>
          </VeeForm>
        </template>
      </FormWrapper>
    </template>
  </PopupCenter>
</template>

<style scoped lang="scss">
.feedback-popup {
  &__font {
    &--checkbox {
      @include text-s;

      color: $color-black-100;

      &:deep(a) {
        display: inline;
        font-weight: 700;
        transition: $ease-out $time-normal;
        transition-property: opacity;

        @include hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__button {
    &--submit {
      width: 100%;
      max-width: em(240);
      margin-top: em(48);
    }
  }

  &__description {
    margin-bottom: em(16);
  }

  &__item {
    &--checkbox {
      margin-top: em(8);
    }
  }
}
</style>
